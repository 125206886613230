import React from 'react';
import ReactDOM from 'react-dom';

import './style.css';

import Game from './Game';
import Main from './Main';
import HowToPlay from './components/HowToPlay';
import Minigame from './components/games/Minigame';
import PlayGame from './components/games/PlayGame';
import Header from './components/Header';
import Footer from './components/Footer';
import Tilesets from './components/Tilesets';
import Minigames from './components/Minigames';
import general from './static/json/general.json';
import levels_strands from './static/json/levels_strands.json';


import CookiePolicy from './components/CookiePolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Contact from './components/Contact';

import './i18n';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-00BG3MRD92');


function Root() {

	const languages = ["en"];
    const games = general["games"];
	const levels = levels_strands["levels"].map(level => level["nameid"]);


	var url = window.location.href;

	if(!url.includes("www") && url.includes("strands")) {
		var newUrl = url;
		newUrl = newUrl.replace("strands-wordle", "www.strands-wordle");
		if(url != newUrl) {

			window.location.href = newUrl;
		}
	}

	return (
		<React.StrictMode >
			<Header/>
			<Router>
				<div className="App">
	            <Routes>
	                    <Route path="/" element={<Minigames lang="en" />} />
	                    <Route path="/*" element={<Minigames lang="en" />} />

	                    <Route path="/cookies" element={<CookiePolicy />} />
	                    <Route path="/privacy" element={<PrivacyPolicy />} />
	                    <Route path="/terms" element={<Terms />} />
	                    <Route path="/contact" element={<Contact />} />

	                    {/*<Route path="/howtoplay" element={<HowToPlay />} />


				        {languages.map(lang =>
				            games.map(game => (
				                <>
						            <Route key={lang} path={`/${lang}/${game}`}
							                element={<Minigame lang={lang} game={game} />}
							        />
						            <Route key={lang} path={`/${lang}/${game}/levels`}
							                element={<Tilesets lang={lang} game={game} />}
							        />

						            {general[game].map(tileset => (
						                <>
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}`}
							                    element={<Game game={game} lang={lang} tileset={tileset} />}
							                />
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}/play`}
							                    element={<PlayGame lang={lang} game={game} tileset={tileset} />}
							                />
						                </>
						            ))}
						            {levels.map(tileset => (
						                <>
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}`}
							                    element={<Game game={game} lang={lang} tileset={tileset} />}
							                />
							                <Route
							                    key={game + "-" + lang}
							                    path={`/${lang}/${game}/${tileset}/play`}
							                    element={<PlayGame lang={lang} game={game} tileset={tileset} />}
							                />
						                </>
						            ))}

						        </>
				            ))
				        )}



	                    */}
	            </Routes>


            </div>
			</Router>
			<Footer/>

		</React.StrictMode>

	);
};


ReactDOM.render(<Root />, document.getElementById('root'));
const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Root />, rootElement);
} else {
  ReactDOM.render(<Root />, rootElement);
}
