import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';
import { useState } from 'react';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';

import ReactGA from 'react-ga4';
import Minigames from './games/Minigames';
import Comments from './Comments';
import '../static/css/tilesets.css';

import levels_strands from '../static/json/levels_strands.json';


function Tileset({game, id, tileset, name, stars, date}) {

	const navigate = useNavigate();
	const { t } = useTranslation();
	const detectedLanguage = window.location.pathname.split('/')[1];

	function bestScore() {
		const best = localStorage.getItem(game + "-" + tileset + "-best");

		if(best != undefined) {
			return (<>{t("bestSoFar")}: {best}</>)
		} else {
			return (<>  </>)
		}
	}

	function getUrl({game, tileset}) {
		return ("/" + detectedLanguage + "/" + game + "/" + tileset);
	}

	function playGame({game, tileset}) {
	    ReactGA.event({
	        category: 'Game',
	        action: 'StartGame'
	    });
    }

	function playTileset({available, game, tileset}) {
		if(available) {
			ReactGA.event({
		        category: 'Game',
		        action: 'StartGame'
		    });
		    window.location.href = getUrl({game, tileset});
		}
    }

	function getImage(available, game, tileset) {

		if(available) {
	        return(<Card.Img variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  /> );
	    } else {
	        return (<Card.Img className="newTileset" variant="top" src={"/images/games/" + game + "/" + tileset + ".png"}  />);
        }
	}


	function getStars() {
		return (
			<div>
				{(stars >= 1) ? <img className="levelStar" src={"/star.png"}/>
				: <img className="levelStar greyStar" src={"/star.png"}/>}
				{(stars >= 2) ? <img className="levelStar" src={"/star.png"}/>
				: <img className="levelStar greyStar" src={"/star.png"}/>}
				{(stars >= 3) ? <img className="levelStar" src={"/star.png"}/>
				: <img className="levelStar greyStar" src={"/star.png"}/>}

			</div>
		);

	}

	if(date === "") {
		available = true;
	} else {
		var available = false;
        const todayDate = new Date();
        const dateDate = new Date(date);
		if(dateDate <= todayDate) {
			available = true;
		}
	}

    return (
        (<Col className='tilesetContainer'>
            <Card onClick={() => playTileset({available, game, tileset})} className='tilesetCard'>

				{
                    available
                    ?  <Card.Img variant="top" src={"/images/levels/" + tileset + ".png"}  />
					: <Card.Img className="newTileset" variant="top" src={"/images/levels/" + tileset + ".png"}  />
				}

				{
                    available
                    ?  <Card.Body>

	                        <Card.Title>{id}. {name}</Card.Title>
	                        <Card.Text className="tilesetDate">{date}</Card.Text>
	                        { getStars() }
	                        <Card.Text>{bestScore()}</Card.Text>
	                        <Button href={getUrl({game, tileset})} className="small-btn" variant="primary"
	                            onClick={() => playGame({game, tileset})}>{t("play")}</Button>
	                    </Card.Body>

	                :  <Card.Body style={{"backgroundColor": "#d3d3d3"}}>
	                        <Card.Title>{id}. {name}</Card.Title>
	                        { getStars() }
	                        <Card.Text>{bestScore()}</Card.Text>
	                        <>Level available from<br/> {date} </>
	                    </Card.Body>

	            }
            </Card>
        </Col>)

    );
}



const Tilesets = ({game, language}) => {
	const { t } = useTranslation();
	var tilesets = levels_strands["levels"];
	var activeTilesets = [];

	const todayDate = new Date();
    var day = 60 * 60 * 24 * 1000;
    for(var i = 0; i < tilesets.length; i++) {
        var ts = tilesets[i];
        var tilesetDate = new Date(ts['date']);
        if(tilesetDate <= new Date(todayDate.getTime() + day)) {
            activeTilesets.push(ts);
        }
    }

	const games = ["pairs"];
	let detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage === "") {
		detectedLanguage = "en";
	}

	function gameToUpper({game}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(game in titles) {
        	return titles[game];
        } else {
			return game.toUpperCase()
		}
	}

	function getStars({tileset}) {
		const best = localStorage.getItem(game + "-" + tileset["nameid"] + "-best");
		var possible = 0;
		if(game == "strands") { possible = 480; }

		var starsAmount = 0;
		if(best != undefined) {
			starsAmount = 1;

			if(game == "strands") {
				if(best >= possible) {
					starsAmount = 3;
				} else if (best >= possible - 30) {
					starsAmount = 2;
				}
			}
		}
		return starsAmount;
	}

	function countStars() {
		var stars = 0;
		for(var i = 0; i < activeTilesets.length; i++) {
			var tileset = activeTilesets[i];
			stars += getStars({tileset});
		}
		return stars;
	}

	const tilesetHtml =  (
        <div>
	        <div className="mainPart">
	        <Helmet>
	            <title>Strands Wordle - {gameToUpper({game})} - Available Levels</title>
	            <meta name="description" content={"Choose your favorite tileset and play " + game} />
	            <link rel="canonical" href={"https://www.strands-wordle.com/" + detectedLanguage + "/tilesets"} />
	            <meta property="og:url" content={"https://www.strands-wordle.com/" + detectedLanguage + "/tilesets"} />
	            <meta property="og:image" content="https://www.strands-wordle.com/banner2.png" />
	            <meta property="og:title" content={"Strands Wordle - " + gameToUpper({game}) + "- Available Levels"} />
	        </Helmet>
	        <Container>
	            <h1>{ gameToUpper({game}) }</h1>
				<h2>{ t("chooseFavorite") }</h2>

	            {
	            <h2><img className="biggerStar" src={"/star.png"}/> { countStars() + " / " + (3*activeTilesets.length - 3) } </h2>
	            }


	            {
	                <Row>
	                    {activeTilesets.map(tileset => ( <Tileset game={game} id={tileset["id"]} tileset={tileset["nameid"]} name={tileset["name"]}
	                    stars={getStars({tileset})} key={tileset["nameid"]} date={tileset["date"]} />))}
	                </Row>
	            }
	        </Container>
			<br/>
	        </div>


			<Comments/>
        </div>
      );

    return tilesetHtml
}
export default Tilesets;

