import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Languages from './Languages'

import '../static/css/header.css';
//import logo from '/icon.png'

import { useTranslation } from 'react-i18next';

function Header() {
	const { t } = useTranslation();
	var detectedLanguage = window.location.pathname.split('/')[1];
	if(detectedLanguage == "en" || detectedLanguage.length > 2) {
		detectedLanguage = "";
	}

	return (
        <Navbar expand="lg" className="bg-body-tertiary tilesNavbar">
            <Container>

                <Navbar.Brand href={"/" + detectedLanguage}>
                    {/*<img className="d-inline-block align-top logo" src="/logo.png" alt="Tiles"/>*/}
					WORD GAMES
                </Navbar.Brand>
                {/*<Languages/> */}

                {/*<a href="/en/strands/levels">
                    <img className="d-inline-block align-right navbar-icon" src="/tilesets.png" alt="Tilesets"/>
                </a>
                <a href="https://www.reddit.com/r/StrandsWordle/">
                    <img className="d-inline-block align-right navbar-icon" src="/reddit.png" alt="Reddit"/>
                </a> */}

                <a href="/contact">
                    <img className="d-inline-block align-right navbar-icon" src="/contact.png" alt="Contact"/>
                </a>
            </Container>
        </Navbar>
    );
}

export default Header;