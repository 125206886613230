import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='text-center text-lg-start text-muted footer'>

      <div className='text-center p-4'>

        <a href="/cookies">Cookie policy</a> &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="/privacy">Privacy policy</a> &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="/terms">Terms</a>

         <br/>
        ©  Copyright 2024 Lean House sp. z o.o.


      </div>
    </MDBFooter>
  );
}