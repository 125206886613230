import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tiles from './components/Tiles';
import Comments from './components/Comments';
import Minigames from './components/games/Minigames';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'
import logo from './icon.png'

import levels_strands from './static/json/levels_strands.json';


import { useNavigate } from "react-router-dom";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-00BG3MRD92');
//ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });


function MainPage({lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function play() {
		ReactGA.event({
	        category: 'Game',
	        action: 'StartGameMainPage'
	    });
	}

	function getUrl() {
		const today = new Date();
		const yyyy = today.getFullYear();
		let mm = today.getMonth() + 1; // Months start at 0!
		let dd = today.getDate();
		if (dd < 10) dd = '0' + dd;
		if (mm < 10) mm = '0' + mm;
		const dateToday = yyyy + "-" + mm + "-" + dd;

		var levels = levels_strands["levels"].filter(level => level["date"] <= dateToday);
		for(var i = 0; i < levels.length; i++) {
			var name = levels[i]["nameid"];
			var best = localStorage.getItem( "strands-" + name + "-best");
			if(best == null) {
				return ("/" + lang + "/strands/" + name);
			}
		}
		return ("/" + lang + "/strands/levels");
	}


    return (
        <div className="mainPart">
        <Container>
          <Row>
            <><h1>{t('title')}</h1>
    	    <p className="intro">{t('shortInstruction')}</p>
    	    <div><Button variant="success" className="default-btn" href={getUrl()}>{t('play')}</Button>
    	    </div></>


    	    <br/>
    	    <a href={"/" + lang + "/strands/levels"}>{t('chooseTileset')}</a>
          </Row>

        </Container>
        </div>
      );
}

function MoreInfo() {
	const { t } = useTranslation();
	return (
		<>
		<div>
			<Container className="moreInfo" >
				<Row>
					<Col sm={9}>
						<h3>{t("strandsHeader1a")}</h3>
						<p>{t("strandsText1a")}</p>
					</Col>
					<Col sm={3} style={{textAlign: 'center'}}><img alt="Example of theme word" src={tile1}/></Col>
				</Row>
				<Row>
					<Col sm={3} style={{textAlign: 'center'}}><img alt="List of words to guess" src={tile2}/></Col>
					<Col sm={9}>
						<h3>{t("strandsHeader1b")}</h3>
						<p>{t("strandsText1b")}</p>
					</Col>
				</Row>
				<Row>
					<Col sm={9}>
						<h3>{t("strandsHeader1c")}</h3>
						<p>{t("strandsText1c")}</p>
					</Col>
					<Col sm={3} style={{textAlign: 'center'}}><img alt="New level every day" src={tile3}/></Col>
				</Row>
			</Container>
		</div>
		<div className="mainPart">
			<Container>
				<Row className="instruction">
					<h3>{t("strandsHeader2")}</h3>
					<p><ol>
						<li>{t("strandsText2a")}</li>
						<img className="" src={"/images/instruction1.png"}/>
						<li>{t("strandsText2b")}</li>
						<img className="" src={"/images/instruction2.png"}/>
						<li>{t("strandsText2c")}</li>
						<img className="" src={"/images/instruction3.png"}/>
						<li>{t("strandsText2d")}</li>
						<img className="" src={"/images/instruction4.png"}/>
						<li>{t("strandsText2e")}</li>
						<img className="" src={"/images/instruction5.png"}/>
					</ol></p>

				</Row>
			</Container>
		</div>
		<div>
			<Container  className="moreInfo" >
				<Row>
					<h3>{t("strandsHeader3")}</h3>
					<p>{t("strandsText3")}</p>
				</Row>
				<Row>
					<h3>{t("strandsHeader4")}</h3>
					<p>{t("strandsText4")}</p>
				</Row>
				<Row>
					<h3>{t("strandsHeader5")}</h3>
				    <p>{t("strandsText5")}</p>
				</Row>
				<Row>
					<h3>{t("strandsHeader6")}</h3>
					<p>{t("strandsText6")}</p>
				</Row>
				<Row>
					<h3>{t("strandsHeader7")}</h3>
					<p>{t("strandsText7")}</p>
				</Row>
			</Container>

			<Container className="moreIconTiles">
	            <Row>
	                <img alt="Tile1" src={tile1} />
	                <img alt="Tile2" src={tile2} />
	                <img alt="Tile3" src={tile3} />
	            </Row>
	        </Container>
		</div>
		</>
	);
}

function MoreGames() {
	return (

		<div className="mainPart">
			<Minigames/>
		</div>
	);
}


function Main({lang}) {

	const { t } = useTranslation();
	const langBasic = (lang == "en") ? "" : lang;
	const description = t("description");
	const title = t("titleShort");

	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.strands-wordle.com/" + langBasic} />
                <meta name="description" content={description}/>

                <meta property="og:image" content="https://www.strands-wordle.com/banner.png" />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={description}/>

            </Helmet>
			<MainPage lang={lang}/>
			<MoreInfo/>

			<Tiles game="strands" language="en"/>

			<Comments/>
		</>

	);
};

export default Main;
