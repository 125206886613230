
import './../../style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import Instruction from './../Instruction';
import general from './../../static/json/general.json';
import levels from './../../static/json/levels_strands.json';

import 'animate.css';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-00BG3MRD92');



function Square({value, line, state, handleMouseDown, onTouchStart, onClick, tileset}) {
	var tiles = "";

	function getBackground() {
		return <div className="image"><img src={"/images/tilesets/background.png"} alt="X"/></div>;
	}

	function color() {
		if(state == "current") {
            return "white";
        } else if(state == "0") {
            return "transparent";
        } else {
            //const colors = ["#a7c957", "#f0f371", "#ffd87a", "#ff9f09", "#f3a78a", "#bcabb7", "#b1c5ff", "#b1e8ff", "#caffb1", "#fff5b1", "#e1e0dc"];
            //return colors[Number(state) - 1];
            return "#e39e52";
        }
	}

	function lines() {
		if(line == 0) {
			return (<></>);
		} else {
			return (<div className={"lineToNext lineToNext" + line} style={{"backgroundColor": color()}}></div>);
		}
	}


    return (
        <td className="smallSquare" onMouseDown={handleMouseDown} onTouchStart={onTouchStart} onClick={onClick}>
            <div className="squareValue" style={{"backgroundColor": color()}} >{value}</div>
            { lines() }

        </td>
    );
}

function Word({word}) {

	function getSquare({p}) {
		if(word["guessed"] == 1) {
			return  (<div className="letterSquare letterGuessed">{word["word"][p]}</div>);
		} else if(word["status"][p] == 1) {
			return  (<div className="letterSquare letterHinted">{word["word"][p]}</div>);
		} else {
			return (<div className="letterSquare"></div>);
		}

	}
	var pos = [];
	for (var i = 0; i < word["word"].length; i++) {
		pos[i] = i;
	}

	return (
		<>

		{pos.map(p => (
			getSquare({p})
		))
		}
		</>
	);
}

function getStart(tilesetid) {

	const board = [];
	const R = 6, C = 8;
	var letters = levels["levels"][tilesetid]["board"];

    for (let i = 0; i < R; i++) {
        board[i] = [];
        for (let j = 0; j < C; j++) {
            board[i][j] = {"value": letters[i*C+j], "state": "0", "line": 0};
        }
    }

	return board;
}

function getWords(words) {

	var wordsWithStatus = [];
	for(var i = 0; i < words.length; i++) {

		var status = [];
		for(var j = 0; j < words[i].length; j++) {
			status[j] = 0;
		}

		wordsWithStatus[i] = {
			"word": words[i],
			"status": status,
			"guessed": 0,
			"points": 0
		};
	}
	return wordsWithStatus;
}

export default function Strands({onFinish, tileset}) {
	const levelData = levels["levels"].filter(level => level["nameid"] == tileset);
	const tilesetid = levelData[0]["id"] - 1;

    const [squares, setSquares] = useState(getStart(tilesetid));
    const [words, setWords] = useState(getWords(levels["levels"][tilesetid]["words"]));
	const category = levels["levels"][tilesetid]["name"].toUpperCase();
	const [nextState, setNextState] = useState(1);

    const [selectedWord, setSelectedWord] = useState("Start guessing");
	var newSelectedWord = selectedWord;

	const [selectedLetters, setSelectedLetters] = useState([]);
    var lettersSelected = selectedLetters;

    const [currentCombo, setCurrentCombo] =  useState(0);
    const [squaresLeft, setSquaresLeft] = useState(48);
    const [moves, setMoves] = useState(0);
    const [points, setPoints] = useState(0);
    var newPoints = 0;
    const [hintsNumber, setHintsNumber] = useState(48);

    const [lines, setLines] = useState([]);
    const R = 6, C = 8;

	function clearSelected(newState) {
	    var newSquares = squares;
	    for(var i = 0; i < R; i++) {
	        for(var j = 0; j < C; j++) {
	            if(newSquares[i][j]["state"] == "current") {
	                newSquares[i][j]["state"] = newState;
	                if(newState == "0") {
	                    newSquares[i][j]["line"] = 0;
	                }
	            }
	        }
	    }
	    setSquares(newSquares);
	    lettersSelected = [];
	    setSelectedLetters(lettersSelected);


	    if(newState == "0") {
	        newSelectedWord = "Try again!";
        } else {
            newSelectedWord = "Good guess!";
        }
        setSelectedWord(newSelectedWord);
	}

	function clearAllSelected() {
		ReactGA.event({
            category: 'Game',
            action: 'ClearWord'
        });
		clearSelected("0");
	}

	function checkIfFinish() {
		for(var i = 0; i < words.length; i++) {
			if(words[i]["guessed"] == 0) {
				return false;
			}
		}
		newSelectedWord = "Congratulations!";
		setSelectedWord(newSelectedWord);

        setTimeout(function() {
            onFinish(newPoints);
		}, 1000);

	}


	function setFirstSelectedLetter(row, column) {

		var newSquares = squares;
	        newSquares[row][column]["state"] = "current";
	        setSquares(newSquares);

	        const newSelectedLetters = [{ row: row, col: column }];
	        lettersSelected = newSelectedLetters;
	        setSelectedLetters(newSelectedLetters);

			newSelectedWord = squares[row][column]["value"];
	        setSelectedWord(newSelectedWord);
	}

	function checkIfFistSelected(row, column) {

		if(selectedLetters.length == 0) {
			setFirstSelectedLetter(row, column);
		} else {
			const lastSelected = selectedLetters[selectedLetters.length - 1];
			if(squares[row][column]["state"] == "current" || (Math.abs(lastSelected.row - row) <= 1 && Math.abs(lastSelected.col - column) <= 1)) {
				doMoveReal(row, column);
			} else {
				clearSelected("0");
				setFirstSelectedLetter(row, column);
	        }
		}

	}


	function doMoveReal(rowIndex, colIndex) {

	    while(lettersSelected.slice(0,-1).some(letter => letter.row == rowIndex && letter.col == colIndex)) {

            var lastLetter = lettersSelected[lettersSelected.length - 1];
            lettersSelected = lettersSelected.slice(0,-1);
            setSelectedLetters(lettersSelected);

            var newSquares = squares;
            newSquares[lastLetter.row][lastLetter.col]["state"] = "0";
            newSquares[lastLetter.row][lastLetter.col]["line"] = 0;

            lastLetter = lettersSelected[lettersSelected.length - 1];
            newSquares[lastLetter.row][lastLetter.col]["line"] = 0;

            setSquares(newSquares);

            newSelectedWord = newSelectedWord.slice(0,-1);
            setSelectedWord(newSelectedWord);
        }


	    if(rowIndex < 0 || rowIndex >= R || colIndex < 0 || colIndex >= C ||
                squares[rowIndex][colIndex]["state"] != 0) {
            return;
        }


	    const lastSelected = lettersSelected[lettersSelected.length - 1];
	    const isValidMove = (
	      rowIndex >= 0 && rowIndex < R &&
	      colIndex >= 0 && colIndex < C &&
	      !lettersSelected.some(letter => letter.row === rowIndex && letter.col === colIndex) &&
	      (Math.abs(lastSelected.row - rowIndex) <= 1 && Math.abs(lastSelected.col - colIndex) <= 1)
	    );

	    if (isValidMove) {
	        const newSelectedLetters = [...lettersSelected, { row: rowIndex, col: colIndex }];
	        lettersSelected = newSelectedLetters;
	        setSelectedLetters(lettersSelected);

	        newSelectedWord = newSelectedWord + squares[rowIndex][colIndex]["value"];
	        setSelectedWord(newSelectedWord);

			var newSquares = squares;
			newSquares[rowIndex][colIndex]["state"] = "current";

			var rowDiv = (lastSelected.row < rowIndex) ? "D" :
						 (lastSelected.row > rowIndex) ? "U" :
						 "0";
			var colDiv = (lastSelected.col < colIndex) ? "R" :
						 (lastSelected.col > colIndex) ? "L" :
						 "0";
			newSquares[lastSelected.row][lastSelected.col]["line"] = colDiv + rowDiv;
			setSquares(newSquares);


			const boardRect = document.querySelector('.gameBody').getBoundingClientRect();
	        const squareSize = (boardRect.bottom - boardRect.top) / R;

			if (lettersSelected.length >= 2) {
	            const [start, end] = lettersSelected.slice(-2);
	            const newLine = { startX: start.col * squareSize + squareSize/2, startY: start.row * squareSize + squareSize/2,
	                        endX: end.col * squareSize + squareSize/2, endY: end.row * squareSize + squareSize/2 };
	            setLines([...lines, newLine]);
	        }

	    }

	}

	function doMove(clientX, clientY) {
		const boardRect = document.querySelector('.gameBody').getBoundingClientRect();
	    const squareSize = (boardRect.bottom - boardRect.top) / R;
	    const colIndex = Math.floor((clientX - boardRect.left) / squareSize);
	    const rowIndex = Math.floor((clientY - boardRect.top) / squareSize);

		// center of [rowIndex, colIndex]
		const squareX = boardRect.left + colIndex * squareSize + squareSize / 2;
		const squareY = boardRect.top + rowIndex * squareSize + squareSize / 2;

	    if(Math.abs(squareX - clientX) >= squareSize/4 || Math.abs(squareY - clientY) >= squareSize/4) {
	        return;
	    }
	    doMoveReal(rowIndex, colIndex);
	}

	function checkForWord() {
		for(var i = 0; i < words.length; i++) {
			if(words[i]['word'] == newSelectedWord) {
				words[i]['guessed'] = 1;
				setHintsNumber(hintsNumber + 1);

				newPoints = points + 10*words[i]['word'].length;
				setPoints(newPoints);
				return true;
			}
		}
    	return false;
    }

	//Handle mouse events
	function handleClick(event, row, column) {
		if(squares[row][column]["state"] != 0 && squares[row][column]["state"] != "current") {
        	return;
        }
		event.preventDefault(); // Prevent default mouse event
		checkIfFistSelected(row, column)
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    const handleMouseMove = (event) => {
        event.preventDefault();
	    const { clientX, clientY } = event;
	    doMove(clientX, clientY);
	  };


    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

	    const foundWord = checkForWord();
	    if(foundWord) {
	        clearSelected(nextState.toString());
	        setNextState(nextState + 1);
	        checkIfFinish();
	    } else {}
		setMoves(moves + 1);
    };


	//Handle mobile events
    function handleTouchStart(event, row, column) {
        if(squares[row][column]["state"] != 0 && squares[row][column]["state"] != "current") {
            return;
        }
        event.preventDefault();
        checkIfFistSelected(row, column);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);
    };

    const handleTouchMove = (event) => {
        event.preventDefault();
        const { clientX, clientY } = event.touches[0];
        doMove(clientX, clientY);
      };

    const handleTouchEnd = () => {
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);

        const foundWord = checkForWord();
	    if(foundWord) {
	        clearSelected(nextState.toString());
	        setNextState(nextState + 1);
	        checkIfFinish();
	    } else {}
		setMoves(moves + 1);
    };


    function showHint() {

		var hintAvailable = false, firstWord = -1;
		for(var i = 0; i < words.length; i++) {
			if(words[i]["guessed"] == 0) {

				for(var j = 0; j < words[i]["word"].length; j++) {
					if(words[i]["status"][j] == 0) {
						hintAvailable = true;
						if(firstWord < 0) {firstWord = i;}
					}
				}
			}
		}
		if(hintsNumber <= 0 || !hintAvailable) {
			ReactGA.event({
                category: 'Game',
                action: 'NoHint'
            });
			setSelectedWord("No more hints!");
			return false;
		}

		ReactGA.event({
            category: 'Game',
            action: 'ShowHint'
        });

		var word = firstWord;
		var letter = Math.floor(Math.random()*words[word]["word"].length);

		while(words[word]["status"][letter] == 1) {
            letter = Math.floor(Math.random()*words[word]["word"].length);
		}
		words[word]["status"][letter] = 1;
    	setMoves(moves+1);
    	setHintsNumber(hintsNumber-1);
    	newPoints = points - 10;
    	setPoints(newPoints);

    }



	var rows = Array(R);
	var columns = Array(C);
	for(var i = 0; i < R; i++) {
		rows[i] = i;
	}
	for(var i = 0; i < C; i++) {
		columns[i] = i;
	}

	function lineCode(row) {
		return (
			<tr className="board-row">{
				columns.map(column => (
					<Square tileset={tileset} key={C*row+column} state={squares[row][column]["state"]} value={squares[row][column]["value"]}
					line={squares[row][column]["line"]} handleMouseDown={(event) => handleClick(event, row, column)}
					onTouchStart={(event) => handleTouchStart(event, row, column)} />
				))}
			</tr>
		)
	};


	const { t } = useTranslation();
	function getInstruction() {
	    return t("moveInfoSameTiles");
	}

	const boardCode = (<div className="fullBoard">

			<Row>
				<h1 className="categoryName">{category}</h1>
			</Row><Row>

				<Col sm={6} className="gameBoardCol">
					<h3 className="gameHeader">{ selectedWord }
					{
						selectedWord != "Start guessing" && selectedWord != "Try again!" && selectedWord != "Good guess!" && selectedWord != "No more hints!"
						? <Button className="clearAllButton" onClick={clearAllSelected}>Clear</Button>
						: <></>
					}

					</h3>

					<Table className="gameBoard"><tbody className="gameBody">
					{rows.map(row => lineCode(row))}
					</tbody></Table>
				</Col>
				<Col sm={6}>
					<Row className="gameStats">
						<div >
							<Row>
                            <Col className="col1" > POINTS <h1>{ points }</h1> </Col>
							<Col className="col1" > <Button className="small-btn" onClick={showHint}>HINT ({hintsNumber}) </Button> </Col>
                            </Row>
                       </div>
						<div className="wordsList"> {
							words.map(word => (
								<div className="wordLine">{<Word word={word}/>}</div>
					        ))
					    }</div>

					</Row>
				</Col>
			</Row>

	    </div>
	);

    return boardCode;
}

