import React from 'react';

import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import tile1 from './static/tile1.png'
import tile2 from './static/tile2.png'
import tile3 from './static/tile3.png'

import Strands from './components/games/Strands';
import Pairs from './components/games/Pairs';

import { Helmet } from 'react-helmet';

import Shares from './components/Shares';
import Comments from './components/Comments';
import Minigames from './components/games/Minigames';

import levels_strands from './static/json/levels_strands.json';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-00BG3MRD92');


function Main({view, game, play, playAgain, finishGame, tileset, lang}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function getResult(date) {
		return localStorage.getItem(game + "-" + tileset + "-" + date);
	}

	function codeForGame() {
		if(game == "strands") {
			return (<Strands onFinish={finishGame} tileset={tileset}/>);
		}
	}

	var code = "";
	if(view === "play") {
    	code = (
    	    <>
    	    <div className="game">
    	        {codeForGame()}
    	        <br/>

    	    </div>
    	    </>);

    } else if(view === "finish") {

		var lastScore = localStorage.getItem(game + "-" + tileset + "-last");
		var dates = localStorage.getItem(game + "-" + tileset + "-dates").split(" ");

		var resultTable = (<div><table className="resultsTable">
			<thead><td>{t('date')}</td><td>{t('bestScore')}</td></thead>
			<tbody>
			{ dates.map(date => <tr key={date}><td>{date}</td><td>{getResult(date)}</td></tr> )}
			</tbody></table></div>);

		function textOnFinish(lastScore) {

			if(game == "same") {
				return t("sameFinishText");
			}

			const bestScores = {
				"tiles": 45,
				"pairs": 24
			}
			if(game == "lines") {
				return "Great score! Play again or choose different level!";
			}

			if(bestScores[game] == lastScore) {
				return t("congrats");
			} else {
				return t("canYou45").replace("X", bestScores[game]);
			}

		}
		function chooseTileset() {
			if(game == "tiles") {
				window.location.href =  "/" + lang + "/levels";
			} else {
				window.location.href =  "/" + lang + "/" + game + "/levels" ;
			}
		}

		function getNextLevelUrl() {
			const today = new Date();
			const yyyy = today.getFullYear();
			let mm = today.getMonth() + 1; // Months start at 0!
			let dd = today.getDate();

			if (dd < 10) dd = '0' + dd;
			if (mm < 10) mm = '0' + mm;

			const dateToday = yyyy + "-" + mm + "-" + dd;

			var levels = levels_strands["levels"].filter(level => level["date"] <= dateToday);
			for(var i = 0; i < levels.length; i++) {
				var name = levels[i]["nameid"];
				var best = localStorage.getItem(game + "-" + name + "-best");
				console.log(best);
				if(best == null) {
					return ("/" + lang + "/" + game + "/" + name);
				}
			}
			return ("/" + lang + "/" + game + "/levels");
		}

		function playNextLevel() {
			window.location.href = getNextLevelUrl();
		}

		function getStars(points) {

			const best = points
			const possible = 480;
			var starsAmount = 0;
			if(best != undefined) {
				starsAmount = 1;
				if(best >= possible) {
					starsAmount = 3;
				} else if (best >= possible - 30) {
					starsAmount = 2;
				}
			}

			return (
				<div>
						<h4>You got {starsAmount} {(starsAmount > 1) ? "stars" : "star"}! </h4>
						{(starsAmount >= 1) ? <img className="levelStar" src={"/star.png"}/>
						: <img className="levelStar greyStar" src={"/star.png"}/>}
						{(starsAmount >= 2) ? <img className="levelStar" src={"/star.png"}/>
						: <img className="levelStar greyStar" src={"/star.png"}/>}
						{(starsAmount >= 3) ? <img className="levelStar" src={"/star.png"}/>
						: <img className="levelStar greyStar" src={"/star.png"}/>}
						<br/>
						<br/>
				</div>
			);

		}


        code =  (
            <div className="finish">
                <h1>{t('win')}</h1>
	            <p>{t('yourScore')} {lastScore}. {t("canYouBeat")} </p>
	            { getStars(lastScore) }
				{ resultTable }
				{ /* <p>{ textOnFinish(lastScore) }</p> */}
	            <div>
	            <Button variant="success" className="default-btn" onClick={playNextLevel}>Play next level</Button><br/>
	            <Button className="small-btn" onClick={chooseTileset}>{t("chooseTileset")}</Button>

	            </div>

	            <br/><br/>
	            <Shares game={game} score={lastScore}/>


            </div>);
    }

    return (
        <>
	        <div className="mainPart">
		        <Container>
		            <Row>
		                { code }
		            </Row>
		        </Container>
	        </div>
		    <Comments/>
	        <br/><br/>

        </>
      );
}



function Game({game, lang, tileset}) {

	const { t } = useTranslation();
    const [currentView, setCurrentView] = useState("play");
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    function finishGame(result) {

		if(localStorage.getItem(game + "-" + tileset + "-best") == null) {
			var finishedSoFar = 1;
			var tilesets = levels_strands["levels"].map(level => level["nameid"]);
			for(var i = 0; i < tilesets.length; i++) {
				var ts = tilesets[i];
				if(localStorage.getItem(game + "-" + ts + "-best") != null) {
					finishedSoFar += 1;
				}
			}

			ReactGA.event({
	             category: 'Game',
	             action: 'FinishedGames_' + game,
	             value: finishedSoFar
	        });
		}

        localStorage.setItem(game + "-" + tileset + "-last", result);
		if(localStorage.getItem(game + "-" + tileset + "-best") == null) {
			localStorage.setItem(game + "-" + tileset + "-best", result);
		}

		if(localStorage.getItem(game + "-" + tileset + "-best") < result) {
            localStorage.setItem(game + "-" + tileset + "-best", result);
		}

        var today = new Date();
		var dateToday = today.getFullYear() + "-" + (today.getMonth()+1) + "-" + today.getDate();

        var todayBest = localStorage.getItem(game + "-" + tileset + "-" + dateToday);
        if(todayBest == null) {
            var allDates = localStorage.getItem(game + "-" + tileset + "-dates");
            if(allDates === null) {
                allDates = dateToday;
            } else {
                allDates = allDates + " " + dateToday;
            }
            localStorage.setItem(game + "-" + tileset + "-dates", allDates);
            localStorage.setItem(game + "-" + tileset + "-" + dateToday, result);
        }

        if(todayBest < result) {
			localStorage.setItem(game + "-" + tileset + "-" + dateToday, result);
		}



        ReactGA.event({
            category: 'Game',
            action: 'FinishGame',
            value: result
        });

		setCurrentView("finish");

		window.scrollTo(0, 0);

	}

	function play() {
		ReactGA.event({
            category: 'Game',
            action: 'StartGame'
        });
    	setCurrentView("play");
    }

	function playAgain() {
		ReactGA.event({
            category: 'Game',
            action: 'StartGameAgain'
        });
    	setCurrentView("play");
    }


	function tilesetToUpper({tileset}) {
		const titles = {
			"tiles": "TILES",
        	"infinite": "INFINITE TILES",
        	"same": "SAME GAME",
        };
        if(tileset in titles) {
        	return titles[tileset];
        } else {
			return tileset.toUpperCase();
		}
	}


	const title = "Strands Wordle - " + ((game == "tiles") ? t("title") :  t(game + "Title")) + " - " + tilesetToUpper({tileset});
	const description = "";


	return (
		<>
			<Helmet>
                <title>{title}</title>
                <link rel="canonical" href={"https://www.strands-wordle.com/" + lang + "/" + tileset} />
                <meta property="og:image" content={"https://www.strands-wordle.com/images/games/" + game + "/default.png"} />
                <meta property="og:title" content={title}/>
                <meta property="og:description" content={title}/>


            </Helmet>

			<Main view={currentView} game={game} play={play} playAgain={playAgain} finishGame={finishGame} tileset={tileset} lang={lang}/>


		</>

	);
};

export default Game;
