import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../static/css/shares.css';
import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-00BG3MRD92');

export default function Shares({game, score}) {

	const { t } = useTranslation();

	var text = t("whatsappText");
	var title = {
		"strands": "Strands Wordle"
	};
	text = text.replace("GAME", title[game]).replace("SCORE", score);

	var detectedLanguage = window.location.pathname.split('/')[1];
    if(detectedLanguage == "" || detectedLanguage.length > 2) {
    	detectedLanguage = "en";
    }
    var link = window.location.href;



    var whatsappText = "whatsapp://send?text=" + text + "%0A⭐⭐⭐ %0A \n" + link;
    var twitterText = "https://twitter.com/intent/tweet?text=" + link;
    const [linkCopied, setLinkCopied] = useState(false);

	function share() {
		ReactGA.event({
            category: 'Game',
            action: 'ShareWhatsapp'
        });
	}
	function shareTwitter() {
		ReactGA.event({
            category: 'Game',
            action: 'ShareTwitter'
        });
	}

	function copyLink() {
		ReactGA.event({
            category: 'Game',
            action: 'CopyLink'
        });
        setLinkCopied(true);
		navigator.clipboard.writeText(link);
	}

	return (
		<div>
			<h3>{t("shareHeader")}</h3>

			<p>{t("shareButtons")}</p>
            <div id="whatsappShare" className="btn btn-success">
	                <a href={whatsappText} onClick={share} data-action="share/whatsapp/share">
	                <svg fill="#fff" preserveAspectRatio="xMidYMid meet" height="1em" width="1em" viewBox="0 0 40 40"><g>
                    <path d="m25 21.7q0.3 0 2.2 1t2 1.2q0 0.1 0 0.3 0 0.8-0.4 1.7-0.3 0.9-1.6 1.5t-2.2 0.6q-1.3 0-4.3-1.4-2.2-1-3.8-2.6t-3.3-4.2q-1.6-2.3-1.6-4.3v-0.2q0.1-2 1.7-3.5 0.5-0.5 1.2-0.5 0.1 0 0.4 0t0.4 0.1q0.4 0 0.6 0.1t0.3 0.6q0.2 0.5 0.8 2t0.5 1.7q0 0.5-0.8 1.3t-0.7 1q0 0.2 0.1 0.3 0.7 1.7 2.3 3.1 1.2 1.2 3.3 2.2 0.3 0.2 0.5 0.2 0.4 0 1.2-1.1t1.2-1.1z m-4.5 11.9q2.8 0 5.4-1.1t4.5-3 3-4.5 1.1-5.4-1.1-5.5-3-4.5-4.5-2.9-5.4-1.2-5.5 1.2-4.5 2.9-2.9 4.5-1.2 5.5q0 4.5 2.7 8.2l-1.7 5.2 5.4-1.8q3.5 2.4 7.7 2.4z m0-30.9q3.4 0 6.5 1.4t5.4 3.6 3.5 5.3 1.4 6.6-1.4 6.5-3.5 5.3-5.4 3.6-6.5 1.4q-4.4 0-8.2-2.1l-9.3 3 3-9.1q-2.4-3.9-2.4-8.6 0-3.5 1.4-6.6t3.6-5.3 5.3-3.6 6.6-1.4z"></path>
                     </g></svg>{t("shareWhatsapp")}</a>
	        </div>
			<br/><br/>
	        <a id="twitterShare"  className="btn btn-success twitter-share-button"
              href={twitterText} onClick={shareTwitter}>
              <svg viewBox="0 0 1200 1227" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="none" className="twtr-color-fill--black">
              <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path>
              </svg>
            {t("shareX")}</a>

            <br/><br/><br/>

            <p>{t("shareText")}</p>
            <input className="link" type="text" defaultValue={link} readOnly="readonly"/><br/>
            {linkCopied
                ? <p className="linkCopied">{t("linkCopied")}</p>
                : <><Button className="small-btn" variant="primary" onClick={copyLink}>{t("copyLink")}</Button><br/></>

            }
            <br/><br/>
	    </div>
    );
}