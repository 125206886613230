import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Helmet } from 'react-helmet';

import CommentsContact from './CommentsContact';

import { useNavigate } from "react-router-dom";
import { renderToStaticMarkup } from 'react-dom/server';


const Contact = () => {

	const tilesetHtml =  (
        <div className="mainPart">
        <Helmet>
            <title>Strands Wordle - Contact</title>
            <meta name="description" content="Contact to strands-wordle.com" />
            <link rel="canonical" href="https://www.strands-wordle.com/contact" />
        </Helmet>
        <Container>
            <h2>Contact</h2>


            <p> If you have any questions or comments, send a message through the contact form, or email us at <b>contact@quizony.com</b>.</p>



            <CommentsContact/>
            <p> We also invite you to join our community on Reddit - <b><a href="https://www.reddit.com/r/StrandsWordle/">CLICK HERE</a></b></p>


        </Container>
        </div>
      );

    return tilesetHtml
}

export default Contact;

